export default {
  name: "shop_data",
  data: [
  {
    url: "album/va-03-supporte-la-sc-ne-locale",
    name: "VA 03 - Supporte la scène locale",
    img_url: "https://f4.bcbits.com/img/a2136756000_10.jpg",
    price: "10€"
  },
  {
    url: "merch/tee-shirt-2021-par-l-o-palo",
    name: "Tee shirt 2021 par Léo Palo",
    img_url: "https://f4.bcbits.com/img/0024221023_10.jpg",
    price: "25€"
  },
  {
    url: "merch/tee-shirt-2021-par-2xc",
    name: "Tee shirt 2021 par 2XC",
    img_url: "https://f4.bcbits.com/img/0024220998_10.jpg",
    price: "25€"
  }
  ]
};
